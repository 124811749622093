<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <ValidationProvider rules="numeric|equal:13" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input
              :value="editableItem.ifu"
              @input="handleInput"
              type="text"
              class="form-control"
              id="ifu"
              name="ifu"
              placeholder="Votre prénom"
            />
            <label for="ifu">N° IFU - Identifiant fiscal</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input
              :value="editableItem.raisonSocial"
              @input="handleInput"
              required
              type="text"
              v-uppercase
              class="form-control"
              id="raisonSocial"
              name="raisonSocial"
              placeholder="Votre raison sociale"
            />
            <label for="nom">Dénomination sociale</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <c-select
              id="formeJuridiques"
              name="formeJuridiques"
              option-label="libelle"
              option-value="id"
              v-model="editableItem.formeJuridique"
              :options="formeJuridiques"
              class="form-select"
            >
            </c-select>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
            <label for="formeJuridique">Forme juridique</label>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input
              :value="editableItem.rccm"
              @input="handleInput"
              type="text"
              class="form-control"
              id="rccm"
              name="rccm"
              placeholder="RCCM"
            />
            <label for="rccm">RCCM</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <textarea
              :value="editableItem.adresseSiege"
              @input="handleInput"
              type="text"
              class="form-control"
              id="adresseSiege"
              name="adresseSiege"
              placeholder="Adresse du siege"
              style="height: 100px"
            ></textarea>
            <label for="adresseSiege">Adresse du siege</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import CIdentifiantInput from "../../../../common/CIdentifiantInput.vue";

export default {
  props: ["value"],
  components: {
    CIdentifiantInput,
  },
  data() {
    return {
      pieces: [],
      editableItem: { ...this.value },
    };
  },
  computed: {
    ...mapGetters({
      formeJuridiques: "formeJuridique/formeJuridiques",
    }),
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 15);
      return today;
    },
  },
  created() {
    this.fetchFormeJuridiques();
  },
  watch: {
    editableItem: {
      handler() {
        this.$emit("input", this.editableItem);
      },
      deep: true,
    },
    "editableItem.hasNip": {
      handler() {
        if (this.editableItem.hasNip === false) {
          this.editableItem.npi = null;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      fetchFormeJuridiques: "formeJuridique/fetchFormeJuridiques",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>
