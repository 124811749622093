<template>
  <div class="">
  <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit()" class="" method="post" accept-charset="utf-8" action="">
      <div class="row">
        <div class="col-sm-6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <c-select id="departement" name="departement" option-label="nom" option-value="id" v-model="editableItem.departement" :options="departements" class="form-select">
              </c-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
              <label for="departement">Département</label>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <c-select  :disabled="editableItem.departement == null" id="commune" name="commune" option-label="nom" option-value="id" v-model="editableItem.commune" :options="communes" class="form-select">
              </c-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
              <label for="commune">Commune</label>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
               <c-select  :disabled="editableItem.commune == null"  id="arrondissement" name="arrondissement" option-label="nom" option-value="id" v-model="editableItem.arrondissement" :options="arrondissements" class="form-select">
              </c-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
              <label for="arrondissement">Arrondissement</label>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-6">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
               <c-select  :disabled="editableItem.arrondissement == null"  id="ville" name="ville" option-label="nom" option-value="id" v-model="editableItem.ville" :options="villes" class="form-select">
              </c-select>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
              <label for="ville">Ville/Village/Quartier</label>
            </div>
          </ValidationProvider>
        </div>
      </div>
      <template>
        
      </template>
    </form>
  </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { setepperFormMixin } from '@/mixins/stepper-form-mixin'

export default {
  props: ['value'],
  data () {
    return {
      editableItem: this.value
    }
  },
  mixins: [ setepperFormMixin ],
  computed: {
    ...mapGetters({
      departements: 'departement/departements',
      communes: 'departement/communes',
      arrondissements: 'departement/arrondissements',
      villes: 'departement/villes'
    })
  },
  watch: {
    'editableItem.departement': {
      handler (val, oldVal) {
        console.log(val, oldVal, this.editableItem.departement)
        if (val != oldVal) {
          this.fetchDepartementCommunes({
            departementId: this.editableItem.departement.id
          })
        }
      },
    }, 
    'editableItem.commune': {
      handler (val, oldVal) {
        console.log(val, oldVal, this.editableItem.commune)
        if (val != oldVal) {
          this.fetchCommuneArrondissements({
            communeId: this.editableItem.commune.id
          })
        }
      },
    }, 
    'editableItem.arrondissement': {
      handler (val, oldVal) {
        console.log(val, oldVal, this.editableItem.arrondissement)
        if (val != oldVal) {
          this.fetchArrondissementVilles({
            arrondissementId: this.editableItem.arrondissement.id
          })
        }
      },
    }, 
  },
  created () {
    this.fetchDepartements()
    this.fetchDepartementCommunes({
      departementId: this.editableItem.departement.id
    })
    this.fetchCommuneArrondissements({
      communeId: this.editableItem.commune.id
    })
    this.fetchArrondissementVilles({
      arrondissementId: this.editableItem.arrondissement.id
    })
  },
  methods: {
    ...mapActions({
      fetchDepartements: 'departement/fetchDepartements',
      fetchCommuneArrondissements: 'departement/fetchCommuneArrondissements',
      fetchDepartementCommunes: 'departement/fetchDepartementCommunes',
      fetchArrondissementVilles: 'departement/fetchArrondissementVilles'
    }),
    handleInput(e){
      this.editableItem[e.target.name] = e.target.value
      this.$emit('input', this.editableItem)
    }
  }
}
</script>

<style scoped>
</style>